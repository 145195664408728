import React from 'react'
import {Link} from 'gatsby'
import { Layout, Parallax, DelayedLink } from '@common'
import { OutboundLink } from "gatsby-plugin-google-analytics"

import {
	page__wrapper,
	page__quote,
	page__parallax,
	page__parallax__inner,
	page__buttons,
	page__button,
	page__button__alt,
	page__testimonial,
	page__list,
	page__grid,
	page__grid__row,
} from './styles/page.module.scss'

const HowItWorks = ({ pageContext, location }) => (
	<Layout
		location={location}
		page={pageContext.page}

	>
		<div className={page__wrapper}>
			<h1><strong>Podoba Ci się</strong> moja inicjatywa?</h1>
			<h1>Bardzo mnie to cieszy <strong>:D</strong></h1>
			<h4>
				Odwiedź otwarte repozytorium ze zbiorem zadań i sprawdź
				czy któreś z zadań Ci odpowiada, a wiele osób mówi, że każde jest ciekawe
			</h4>
			<h1>Satysfakcja gwarantowana!</h1>
			<div className={page__buttons}>
				<div className={`${page__button} ${page__button__alt}`}>
					<OutboundLink target="_blank" href="https://github.com/Przemocny/zbior-zadan-html-css-js-react">Zbiór zadań na Github</OutboundLink>
					<span>&gt;</span>
				</div>

				<div className={`${page__button}`}>
				<span>&lt;</span>
					<DelayedLink to={'/help/'}>Jak możesz mi pomóc?</DelayedLink>
				</div>
			</div>
    </div>
	</Layout>
)

export default HowItWorks
